:root {
  font-feature-settings: "liga" 1, "calt" 1;
  font-family: Inter, sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

body {
  margin: 0;
}

#main {
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh;
  display: flex;
}

#sidebar {
  flex: 1 0 20%;
  min-width: 24em;
  max-width: 32em;
  padding: 1em;
  overflow: auto;
}

#sidebar h2 {
  font-size: 2em;
}

#sidebar h3 {
  font-size: 1.2em;
}

#sidebar h2, h3 {
  margin: .5em 0;
}

#sidebar ul {
  padding-left: 2em;
}

.level-chip {
  color: #fff;
  font-feature-settings: "tnum";
  border-radius: .25em;
  margin-right: .4em;
  padding: .25em .5em;
  line-height: 2;
}

.level-chip.level-1 {
  background-color: #2ecc71;
}

.level-chip.level-2 {
  background-color: #f1c40f;
}

.level-chip.level-3 {
  background-color: #e67e22;
}

.level-chip.level-4 {
  background-color: #e74c3c;
}

#map {
  flex: 4 80%;
}

/*# sourceMappingURL=index.0271d2db.css.map */
